import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import { Link } from "gatsby"

const PressRelease = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO
      title="Press Release"
      description=""
      keywords={["", "", ""]}
      lang="en-gb"
    />
    <div className="pr-hero-section">
      <div className="privacy-hero-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Press Release</h1>
                <h2>
                  Metsi Transforms to Accelerate Customers' Transition to a
                  Digital World
                </h2>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="outer-container">
      <div
        className="inner-container"
        style={{ textAlign: "left", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <h3 style={{ textAlign: "center" }}>
          Metsi Technologies' evolution to Metsi 2.0 amplifies its vision to
          adapt to the rapidly-changing markets its customers are servicing.
        </h3>

        <div style={{ textAlign: "left" }}>
          <h4>
            31 May 2021: Leading global digital systems integrator Metsi
            Technologies today unveils its rebrand to introduce Metsi 2.0. With
            operations in the United Kingdom, United States, Germany, and The
            Netherlands, an expanded portfolio of offerings has been created to
            accelerate customer's journey to digital maturity.
          </h4>
        </div>
        <div>
          <p>
            Says Metsi's Global CIO, Ghufran Shah: "This enriched selection of
            offerings combining established and novel technologies demonstrate
            real value and outcomes for customers embarking on a journey of
            digital transformation."{" "}
          </p>

          <p>
            This new Metsi global brand expresses the company’s dedication to
            transforming IT through business agility and service innovation,
            making it easier for customers to define, deliver, manage, and
            consume secure services.
          </p>

          <p>
            Rachael Babcock, CEO of Metsi US, states that “it is more important
            than ever that IT enterprises achieve digital maturity, whether it’s
            through continuous digital innovation, digital applications, hybrid
            multi-cloud adoption, or Anything-as-a-Service.”
          </p>

          <p>
            "Metsi is dedicated to empowering customers and partners to
            accelerate their adoption of digital technologies to meet increasing
            global business demand quickly and efficiently, Babcock says. "We
            consistently deliver quicker time-to-use cases, and faster
            application deployment and migration times through our agile
            automation engineering," she notes.
          </p>
        </div>

        <div>
          <h3> Forging responsive relationships </h3>
          <p>
            In an IT landscape that changed rapidly through 2020 and will
            continue to do so, Metsi is forging new relationships to complement
            its portfolio and strengthen the Metsi footprint across its
            Visibility and Insights, Automation and Intelligence and Application
            and Service Management Blueprint.{" "}
          </p>

          <p>
            Able to source technologies aligned to its core business, Metsi's
            ability to integrate with customers while bringing time to value
            will determine each strategic vendor relationship Metsi offers.
          </p>

          <p>
            Steve James, Co-founder of Metsi Technologies, adds: "Our vision is
            to adapt to our customer's ever-changing world, grow with our
            customers as a trusted advisor, and ensure customers and partners
            benefit from the innovation we bring to the industry."
          </p>

          <p>
            The company's key focus is customer-centricity and capabilities to
            provide a path to digital maturity based on a Service-orientated
            architecture.
          </p>
        </div>

        <div>
          <h3>Change at the speed of digital</h3>
          <p>
            "We’re operating in a continually changing digital environment, and
            leaders delivering digital maturity solutions and services need to
            be agile and responsive,” says Mik Hillewaert, Global CEO Metsi
            Technologies.{" "}
          </p>

          <p>
            “The rebrand is not merely cosmetic - it allows us to evolve with
            customer needs as they occur, so Metsi maintains its market-leading
            position in offering our customers the best in class digital
            maturity solution, every day for every client.”
          </p>

          <p>
            Metsi Technologies’ evolution to Metsi 2.0 amplifies its vision to
            adapt to the rapidly-changing markets its customers are servicing.
            The introduction of the tree in our logo symbolises growth and fresh
            images of nature. The natural elements are a reflection of the Metsi
            2.0 vision and culture to adapt, grow and benefit while providing a
            sustainable and profitable business to its staff and shareholders.
          </p>

          <p>
            Says Shah: “The pandemic has reshaped the future of work,
            accelerating the move to the choice of Cloud, while ensuring Digital
            initiatives are accelerated, aligned to the business needs and
            strategy. Metsi is on a mission to help customers move beyond ‘the
            new normal’ into a ‘brave new world’ to support a digital workforce.
          </p>

          <p>
            “We will walk alongside them in their accelerated adoption of
            digital technologies and enable strategic investment in XaaS with
            cloud technologies to ensure they adapt, grow and benefit from our
            expertise in helping to achieve their vision.”{" "}
          </p>
        </div>

        <div>
          <h3>Note To Editor: </h3>

          <p>
            <Link to="/about" style={{ color: "#005b85", fontWeight: "600" }}>
              About Metsi Technologies
            </Link>
          </p>
          <p>
            Founded in 2012, Metsi is a privately owned global digital Systems
            Integrator with operations in the United Kingdom, United States,
            Germany, and The Netherlands.
          </p>

          <p>
            In the first few years of Metsi’s growth, our engineers were experts
            in service catalog, infrastructure and cloud management with
            technologies such as NewScale, Cloupia and CliQr. As a result of
            vendor acquisitions, Metsi built strategic partnerships with Cisco,
            Puppet Labs, and Aveksa to offer professional services around
            automation and security. As a result of our customer success, Metsi
            became Cisco’s first software Integrator Partner, and started to
            offer full-stack engineering for Software Defined Data Center. This
            laid the foundation for Metsi to diversify its portfolio to become a
            global digital Systems Integrator around leading edge technologies
            in EMEA and the US.
          </p>

          <p>
            With the launch of Metsi US in 2018, Metsi expanded into Full Stack
            Visibility & Observability with AppDynamics certified engineering.
            Today, in partnership with the Cisco DoD teams, Metsi US designs,
            delivers, and productises custom IaaS and PaaS solutions that
            deliver faster application deployment for the US Department of
            Defense.
          </p>

          <p>
            Like water (“metsi” translates “water” in Tswana), we adapt. To
            adapt is to adjust to different conditions or environments.
          </p>

          <p>
            We seek to be fluid, transformative, and beneficial to all teams,
            technology vendors, and partners as we collaborate with our
            customers to help them adapt to the ever-changing demands along
            their digital transformation journey.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PressRelease
